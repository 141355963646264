import React from "react"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Core/Seo"
import { graphql } from "gatsby"
import styled from "styled-components"
import ProductCard from "../components/PLP/ProductCard"
import Header from "../components/PLP/Header"
import colors from "../styles/colors"
import breakpoints from "../styles/breakpoints"
import {
  MOBILE_HEADER_HEIGHT,
  DESKTOP_HEADER_HEIGHT,
} from "src/components/Layout/Layout"
import { getProductLink } from "src/services/product/link"

const ContentContainer = styled.div`
  padding-top: ${MOBILE_HEADER_HEIGHT}px;
  max-width: ${breakpoints.max};
  margin: auto;

  @media (min-width: ${breakpoints.md}) {
    padding-top: ${DESKTOP_HEADER_HEIGHT}px;
  }
`

const StyledGrid = styled.div`
  padding: 5vw 6vw;
  display: grid;
  gap: 30px;

  @media (min-width: ${breakpoints.sm}) {
    grid-template-columns: repeat(2, 1fr);
    grid-column-gap: 7vw;
    grid-row-gap: 5vw;
  }
  @media (min-width: ${breakpoints.md}) {
    padding: 6vw;
    grid-template-columns: repeat(3, 1fr);
  }
`

const ProductCategoryTemplate = (props) => {
  const { location, data } = props;
  const path = location.pathname

  var products = data.allSwellProduct.edges;

  const metaTitle = data.swellCategory.meta_title
  const metaDescription = data.swellCategory.meta_description
  const content = data.content;

  const metaNoIndex = [{
    name: "robots",
    content: "noindex"
  }]

  let sharingImage = false
  if (data.swellCategory.images) {
    sharingImage = data.swellCategory.images[0].file.url
  }

  let contentfulProducts = props.data.allContentfulProduct.edges;
  let productMap = {};
  for (const contentfulProduct of contentfulProducts)
  {
    let newObj = {};
    newObj.title= contentfulProduct.node.title;
    productMap[contentfulProduct.node.slug] = newObj;
  }
  products = products.map(x=>({ node: {...x.node, contentfulTitle: (productMap[x.node.slug] ? (productMap[x.node.slug].title ?? x.node.name) : x.node.name ) }}));
  

  return (
    <Layout dark bg={colors.lightGrey}>
      <Seo title={metaTitle} description={metaDescription} path={path} image={sharingImage} meta={metaNoIndex}/>
      <ContentContainer>
        {content && <Header {...content} />}
        <StyledGrid>
          {products.map((product, index) => {
            const {
              images,
              name,
              sale_price,
              sale,
              price,
              slug,
              currency,
              stock_status,
              options,
              contentfulTitle
            } = product.node
            return (
              images && images.length > 0 ? 
                <ProductCard
                gatsbyImageData={
                  images[0].fileLocal?.childImageSharp.gatsbyImageData
                }
                price={price}
                sale_price={sale_price}
                sale={sale}
                name={contentfulTitle ?? name}
                path={getProductLink(slug)}
                currency={currency}
                stock_status={stock_status}
                options={options}
                key={index}
              /> : <></>

              
  
            )
          })}
        </StyledGrid>
      </ContentContainer>
    </Layout>
  )
}

export const CategoryProductQuery = graphql`
  query CategoryProductQuery(
    $slug: String!
    $category_id: [SwellProductFieldsEnum]!
  ) {
    allSwellProduct(
      filter: {
        categories: { elemMatch: { slug: { eq: $slug } } }
        active: { eq: true }
      }
      sort: { fields: $category_id }
    ) {
      edges {
        node {
          price
          currency
          name
          slug
          stock_status
          sku
          options {
            values {
              name
              price
              id
            }
            name
          }
          images {
            fileLocal {
              childImageSharp {
                gatsbyImageData(aspectRatio: 0.76)
              }
            }
          }
          sale_price
          sale
        }
        
      }
    }
    swellCategory(slug: { eq: $slug }) {
      meta_description
      meta_title
      slug
      images {
        file {
          url
        }
      }
    }
    content: contentfulCollection(slug: { eq: $slug }) {
      title
      text {
        raw
      }
      slug
      images {
        gatsbyImageData
        title
      }
    }
    allContentfulProduct(filter: {}) {
      edges {
        node {
          title
          slug
        }
      }
    }
  }
`

export default ProductCategoryTemplate
